.px-btn {
  padding: 0 25px;
  line-height: 42px;
  position: relative;
  display: inline-block;
  background: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none !important;
  transition: 0.4s;
  letter-spacing: 0.3px;
  cursor: pointer !important;
  &.px-btn-white {
    background: $px-white;
    border-color: $px-white;
    color: $px-dark;
    .theme-light & {
      color: $px-white;
      background: $px-dark;
      border-color: $px-dark;
    }
    &:hover {
      background: transparent;
      color: $px-white;
      transform: translateY(-3px);
      .theme-light & {
        color: $px-dark;
      }
    }
  }
}
